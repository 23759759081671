<template>
    <div class="auditRaecords">
        <el-form ref="formRef" :model="form" label-width="100px" class="demo-ruleForm" :rules="rules">
            <el-table
                :data="form.tableData"
                :default-sort="{ prop: 'createTime', order: 'descending' }"
                style="width: 100%; height: 100%"
                header-row-class-name="table-header"
                :header-cell-style="{
                    background: '#F7F8FA',
                    color: '#333333',
                    height: '54px',
                    'font-size': '14px',
                }"
            >
                <el-table-column prop="indexName" label="指标名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sourceName" label="数源应用名称" show-overflow-tooltip width="130"></el-table-column>
                <el-table-column prop="indexSecret " label="是否涉密" class-name="textCenter">
                    <template #default="{ row }">
                        <span v-if="row.indexSecret == 0" slot="reference">是</span>
                        <span v-if="row.indexSecret == 1" slot="reference">否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="indexNum" label="目标值" class-name="textCenter"></el-table-column>
                <el-table-column prop="unitNameAndOrgNum" label="责任单位"></el-table-column>
                <el-table-column label="操作" width="150" prop="verifyType" class-name="textCenter">
                    <template #default="scope">
                        <el-form-item class="content" :prop="`tableData[${scope.$index}].verifyType`" :rules="rules.verifyType">
                            <el-radio-group v-model="scope.row.verifyType" @change="handleRadio">
                                <el-radio :label="0" size="large">通过</el-radio>
                                <el-radio :label="1" size="large">驳回</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" width="200" label="审核意见" class-name="textCenter">
                    <template #default="scope">
                        <el-form-item
                            class="content"
                            :prop="`tableData[${scope.$index}].remark`"
                            :rules="scope.row.verifyType == 1 ? rules.remark : rules.noremark"
                            :show-overflow-tooltip="false"
                        >
                            <el-input
                                v-model="scope.row.remark"
                                type="textarea"
                                :autosize="{ minRows: 1, maxRows: 8 }"
                                maxlength="200"
                                autocomplete="off"
                                placeholder="请输入"
                            />
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
let rules = {
    verifyType: [{ required: true, message: '请选择', trigger: 'blur' }],
    remark: [{ required: true, message: '请填写审核意见', trigger: 'blur' }],
    noremark: [{ required: false, message: '请填写审核意见', trigger: 'blur' }],
};
let form = ref({
    tableData: [
        {
            indexName: '张三',
        },
    ],
});
const { proxy } = getCurrentInstance();
let query = ref({
    page: 1,
    size: 15,
});
let formRef = ref(null);
let total = ref(0);
let emit = defineEmits();
let props = defineProps(['id', 'type']);
const { id, type } = props;
console.log(id, type);
// type 1为指标查看2为重大任务查看3为任务执行查看
if (id) {
    getList();
}
function getList() {
    proxy.$api.frontIndex.getIndexesByActionId(id).then(res => {
        if (res.code == 200) {
            form.value.tableData = res.data;
            emit('auditSuccess', form.value.tableData);
        }
    });
}
function handleRadio(val) {
    console.log(val);
    emit('auditSuccess', form.value.tableData);
}
const submit = async () => {
    return await formRef.value.validate((valid, filed) => {
        return valid;
    });
};
defineExpose({ submit });

onMounted(() => {
    // getList();
});
</script>

<style scoped lang="stylus">
.auditRaecords
    width: 100%
    /deep/ .el-table
        min-height 120px
    .pagination
        text-align: right;
    .status
        display: inline-block;
        width: 68px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        &.status1
            background: rgba(68, 203, 87, 0.2);
            color: #44cd40;
        &.status3
            background: rgba(255, 127, 127, 0.2);
            color: #44cd40;
    .content
        /deep/ .el-form-item__content
            margin-left: 0px !important
        /deep/ .el-radio-group
            flex-wrap: nowrap
        /deep/ .el-radio
            margin-right: 16px
/deep/ .el-pagination
    justify-content: flex-end
    margin-top 18px
</style>
