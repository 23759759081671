<template>
    <div class="class">
        <el-form :model="form" :disabled="disabled" :rules="rules" ref="ruleForm">
            <el-row>
                <el-col :span="22">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="年度：" prop="actionYear" :label-width="formLabelWidth">
                                <el-select v-model="form.actionYear">
                                    <el-option
                                        :label="new Date().getFullYear() - 1"
                                        :value="new Date().getFullYear() - 1"
                                    ></el-option>
                                    <el-option
                                        :label="new Date().getFullYear()"
                                        :value="new Date().getFullYear()"
                                    ></el-option>
                                    <el-option
                                        :label="new Date().getFullYear() + 1"
                                        :value="new Date().getFullYear() + 1"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="牵头单位：" prop="actionDeptIdList" :label-width="formLabelWidth">
                                <el-cascader
                                    @change="handleChange"
                                    ref="cascader"
                                    filterable
                                    clearable
                                    v-model="form.actionDeptIdList"
                                    :props="defaultProps"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="22">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="重大任务名称：" prop="actionName" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionName"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="目标体系：" prop="actionTarget" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionTarget"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="22">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="工作体系：" prop="actionWork" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionWork"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="政策体系：" prop="actionPolicy" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionPolicy"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="22">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="评价体系：" prop="actionEvaluation" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionEvaluation"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="进度安排：" prop="actionProgress" :label-width="formLabelWidth">
                                <el-input
                                    type="textarea"
                                    rows="2"
                                    placeholder="请输入"
                                    v-model="form.actionProgress"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="22">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="协同单位：" prop="actionJointDeptIdList" :label-width="formLabelWidth">
                                <el-cascader
                                    @change="handleChange2"
                                    ref="cascader2"
                                    filterable
                                    clearable
                                    v-model="form.actionJointDeptIdList"
                                    :props="defaultProps"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="是否涉密："
                                @input="groupInput"
                                prop="actionSecret"
                                :label-width="formLabelWidth"
                            >
                                <el-radio-group v-model="form.actionSecret">
                                    <el-radio label="0" size="large">是</el-radio>
                                    <el-radio label="1" size="large">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-form-item
                    label="密级："
                    v-if="envType === 'intranet' && form.actionSecret === '0'"
                    prop="classification"
                    :label-width="formLabelWidth"
                >
                    <el-select v-model="form.classification" class="select-input" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in classificationMap"
                            :key="index"
                            :label="item"
                            :value="index"
                        />
                    </el-select>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
let dialogFormVisible = ref(true);
let formLabelWidth = '150px';
let route = useRoute();
let rules = {
    actionYear: [{ required: true, message: '请选择年度', trigger: 'blur' }],
    actionDeptIdList: [{ required: true, message: '请选择牵头单位', trigger: 'blur' }],
    actionName: [{ required: true, message: '请输入重大任务名称', trigger: 'blur' }],
    actionTarget: [{ required: true, message: '请输入目标体系', trigger: 'blur' }],
    actionWork: [{ required: true, message: '请输入工作体系', trigger: 'blur' }],
    actionPolicy: [{ required: true, message: '请输入政策体系', trigger: 'blur' }],
    actionEvaluation: [{ required: true, message: '请输入评价体系', trigger: 'blur' }],
    actionProgress: [{ required: true, message: '请输入进度安排', trigger: 'blur' }],
};
const { proxy } = getCurrentInstance();
const envType = computed(() => {
    return process.env.VUE_APP_API_ENV_IRONMENT;
});
const classificationMap = {
    1: '秘密',
    2: '机密',
};
const cascader = ref(null);
let cascader2 = ref(null);
let emit = defineEmits();
let props = defineProps(['id', 'formData', 'disabled']);
const { id, formData, disabled } = props;
console.log(id, formData, disabled);
let form = ref({
    actionYear: new Date().getFullYear(),
});

let ruleForm = ref(null);
const saveItem = async () => {
    emit('success', form.value);
    return await ruleForm.value.validate((valid, filed) => {
        return valid;
    });
};
defineExpose({ saveItem });

const getTaskById = () => {
    //根据id获取任务详情
    id &&
        proxy.$api.frontAction.getById(id).then((res) => {
            if (res.code == 200) {
                form.value = res.data;
                console.log('form',form.value);
            }
        });
};
getTaskById();
const defaultProps = {
    lazy: true,
    multiple: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'main';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        }); //.then(  => {
        if (res) {
            console.log(res);
            let nodes = res.map((item) => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                node.data.leaf = true;
            }
            resolve(nodes);
        }
        // });
    },
};
const collaborativeProps = {
    lazy: true,
    multiple: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'top';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        }); //.then(  => {
        if (res) {
            let nodes = res.map((item) => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                node.data.leaf = true;
            }
            resolve(nodes);
        }
        // });
    },
};
let firstDeptId = '';
let firstDeptName = '';
let firstDeptId2 = '';
let firstDeptName2 = '';
function handleChange(val) {
    let nodesInfo = cascader.value.getCheckedNodes();
    console.log(nodesInfo, val);
    firstDeptName = nodesInfo.map((e) => e.text).join(',');
    firstDeptId = nodesInfo.map((e) => e.value).join(',');
    form.value.actionDeptName = firstDeptName;
    form.value.actionDeptId = firstDeptId;
    console.log(form.value);
}

function handleChange2(val) {
    let nodesInfo = cascader2.value.getCheckedNodes()[val.length - 1];
    console.log(nodesInfo, val);
    firstDeptName2 += nodesInfo.data.label + ',';
    firstDeptId2 += nodesInfo.data.value + ',';
    form.value.actionJointDeptName = firstDeptName2;
    form.value.actionJointDeptId = firstDeptId2;
}
function groupInput(val) {
    console.log(val);
    if (val == 1) {
        form.value.classification = '';
    }
}
</script>

<style scoped lang="stylus"></style>
