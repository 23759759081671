export default {
    indexColunm: [
        {
            prop: 'indexName',
            label: '指标名称',
            width: '',
        },
        {
            prop: 'sourceName',
            label: '数源应用名称',
            width: '',
        },
        {
            prop: 'indexTypeText',
            label: '是否有目标值',
            width: '',
            class: 'textCenter',
        },
        {
            prop: 'indexNum',
            label: '目标值',
            width: '100',
            class: 'textCenter',
        },
        {
            prop: 'indexSecretText',
            label: '是否涉密',
            width: '',
            class: 'textCenter',
        },
        {
            prop: 'unitNameAndOrgNum',
            label: '责任单位',
            width: '',
        },
    ],
};
